import { Currency, Pair, Token } from 'titano-sdk3'
import { Button, ChevronDownIcon, Text, useModal, Flex, Box, MetamaskIcon } from '@pancakeswap/uikit'
import styled, { useTheme } from 'styled-components'
import { registerToken } from 'utils/wallet'
import { isAddress } from 'utils'
import { useTranslation } from 'contexts/Localization'
import { WrappedTokenInfo } from 'state/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input } from './NumericalInput'
import { CopyButton } from '../CopyButton'

const NumericalInput = styled(Input)`
  color: ${({ theme }) => theme.colors.hardTextColor};
`

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ selected }) => (selected ? '4px 0' : '4px 0')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  // background-color: ${({ theme }) => theme.colors.inputPanelBackground}
  font-size: 0.75rem;
  line-height: 1rem;
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  z-index: 1;
`
const Container = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.inputPanelBackground};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.hardTextColor};
  font-weight: 700;
`

const StyledSoftText = styled(Text)`
  color: ${({ theme }) => theme.colors.softTextColor};
`

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  color: ${({ theme }) => theme.colors.textSubtle};
`

const StyledSubText = styled(Text)`
  color: ${({ theme }) => theme.colors.softTextColor};
`

const StyledMaxButton = styled(Button)`
  background: ${({ theme }) => theme.colors.maxButtonBackground};
  font-size: 10px;
`

interface CurrencyInputPanelProps {
  mb?: string | number
  value: string
  usdAmount?: number
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}
export default function CurrencyInputPanel({
  mb,
  value,
  usdAmount,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
}: CurrencyInputPanelProps) {
  const theme = useTheme()
  const { account, library } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  const token = pair ? pair.liquidityToken : currency instanceof Token ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )
  if (['add-liquidity-input-tokena', 'add-liquidity-input-tokenb'].includes(id))
    return (
      <Box position="relative" id={id} mb={mb}>
        <Flex mb="6px" alignItems="center" justifyContent="space-between">
          <Flex>
            <CurrencySelectButton
              className="open-currency-select-button"
              selected={!!currency}
              onClick={() => {
                if (!disableCurrencySelect) {
                  onPresentCurrencyModal()
                }
              }}
            >
              <Flex alignItems="center" justifyContent="space-between">
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                ) : null}
                {pair ? (
                  <Text id="pair" bold>
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </Text>
                ) : (
                  <StyledSubText id="pair" fontSize={14}>
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length,
                        )}`
                      : currency?.symbol) || t('Select a currency')}
                  </StyledSubText>
                )}
                {!disableCurrencySelect && <ChevronDownIcon />}
              </Flex>
            </CurrencySelectButton>
            {token && tokenAddress ? (
              <Flex style={{ gap: '4px' }} alignItems="center">
                <CopyButton
                  width="16px"
                  buttonColor="textSubtle"
                  text={tokenAddress}
                  tooltipMessage={t('Token address copied')}
                  tooltipTop={-20}
                  tooltipRight={40}
                  tooltipFontSize={12}
                />
                {library?.provider?.isMetaMask && (
                  <MetamaskIcon
                    style={{ cursor: 'pointer' }}
                    width="16px"
                    onClick={() =>
                      registerToken(
                        tokenAddress,
                        token.symbol,
                        token.decimals,
                        token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                      )
                    }
                  />
                )}
              </Flex>
            ) : null}
          </Flex>
          {account && (
            <Text onClick={onMax} fontSize="12px" style={{ display: 'inline', cursor: 'pointer' }}>
              {!hideBalance && !!currency
                ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
                : ' -'}
            </Text>
          )}
        </Flex>
        <InputPanel>
          <Container
            as="label"
            style={{
              padding: '10px 13px 12px',
              borderRadius: '10px',
            }}
          >
            <LabelRow>
              <NumericalInput
                style={{
                  fontWeight: 700,
                  fontSize: '24px',
                  opacity: currency ? '1' : '0.4',
                }}
                disabled={!currency}
                className="token-amount-input"
                value={value}
                placeholder="0.00"
                onUserInput={(val) => {
                  onUserInput(val)
                }}
              />
            </LabelRow>
            <InputRow selected={disableCurrencySelect} style={{ float: 'right', marginTop: 8 }}>
              {account && label !== 'To' && (
                <StyledMaxButton onClick={onMax} scale="xs">
                  {t('Max')}
                </StyledMaxButton>
              )}
            </InputRow>
          </Container>
        </InputPanel>
      </Box>
    )
  return (
    <Box position="relative" id={id}>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex-start" mr={18}>
          <div>
            {['To', 'To (estimated)'].includes(label) ? (
              <>
                <Flex width="100%" alignItems="center" justifyContent="space-between">
                  <Flex flexDirection="column" alignItems="flex-start" mr={10}>
                    <StyledSoftText fontSize="12px">You Receive</StyledSoftText>
                  </Flex>
                  {token && tokenAddress ? (
                    <Flex style={{ gap: '8px' }} alignItems="center">
                      <CopyButton
                        width="16px"
                        buttonColor={theme.colors.softTextColor}
                        text={tokenAddress}
                        tooltipMessage={t('Token address copied')}
                        tooltipTop={-20}
                        tooltipRight={40}
                        tooltipFontSize={12}
                      />
                      {library?.provider?.isMetaMask && (
                        <MetamaskIcon
                          style={{ cursor: 'pointer' }}
                          width="16px"
                          onClick={() =>
                            registerToken(
                              tokenAddress,
                              token.symbol,
                              token.decimals,
                              token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                            )
                          }
                        />
                      )}
                    </Flex>
                  ) : null}
                </Flex>
              </>
            ) : (
              <StyledSoftText fontSize="12px">You Send</StyledSoftText>
            )}
          </div>
        </Flex>
        <Flex flexDirection="column">
          {account && (
            <StyledSoftText onClick={onMax} fontSize="12px" style={{ display: 'inline', cursor: 'pointer' }}>
              {!hideBalance && !!currency
                ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
                : ' -'}
            </StyledSoftText>
          )}
        </Flex>
      </Flex>
      <Flex mt="20px" mb="2px" alignItems="flex-start" justifyContent="space-between">
        <Container as="label" style={{ backgroundColor: 'none', background: 'none' }}>
          <LabelRow
            style={{
              backgroundColor: 'none',
            }}
          >
            <NumericalInput
              style={{
                fontWeight: 700,
                fontSize: '24px',
                textAlign: 'left',
              }}
              className="token-amount-input"
              value={value}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
          </LabelRow>
          <InputRow selected={disableCurrencySelect}>
            {usdAmount && (
              <StyledSoftText onClick={(e) => e.preventDefault()} fontSize="12px" marginRight="7px">
                ${usdAmount} USD
              </StyledSoftText>
            )}
            {account && currency && showMaxButton && label !== 'To' && (
              <StyledMaxButton onClick={onMax} scale="xs">
                {t('Max')}
              </StyledMaxButton>
            )}
          </InputRow>
        </Container>
        <Flex>
          <CurrencySelectButton
            className="open-currency-select-button"
            selected={!!currency}
            onClick={() => {
              if (!disableCurrencySelect) {
                onPresentCurrencyModal()
              }
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
              ) : null}
              {pair ? (
                <StyledText id="pair">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </StyledText>
              ) : (
                <StyledSubText id="pair" fontSize={16}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length,
                      )}`
                    : currency?.symbol) || t('Select a currency')}
                </StyledSubText>
              )}
              {!disableCurrencySelect && <StyledChevronDownIcon />}
            </Flex>
          </CurrencySelectButton>
        </Flex>
      </Flex>
    </Box>
  )
}
